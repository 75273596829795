import { Alert, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, OutlinedInput, Paper, Slide, Snackbar, TextField, Typography, capitalize } from '@mui/material'
import React from 'react'
import handCard from "../images/handCard.png";
import imageLogo from "../images/eezib.png"
import { Link, useNavigate } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import Cookies from 'js-cookie'; 
import env from 'react-dotenv';
import forgetPass from "../images/forgetPass.png"
import CloseIcon from '@mui/icons-material/Close';
import otpImage from "../images/otpImage.png";
import newPassImage from "../images/new pass.png"
import concentData from './loginConcent';
import { HandleApi } from '../HandleApi';
import Footer from './Footer';
import registerCostumer from "../images/register_costumer.jpg";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import moment from 'moment';
import { useTheme, useMediaQuery } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import SnackBarSuccess from '../components/SnackBarSuccess';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Login = () => {
  
  const [email, setEmail] = React.useState('');
  const [password , setPassword] = React.useState('');
  const [emailError , setEmailError] = React.useState('');
  const [error , setError] = React.useState(false);
  const [progress, setProgress] = React.useState(false)
  const [jsonSuccess , setJsonSuccess] = React.useState('');
  const [jsonError, setJsonError] = React.useState('');
  const [open , setOpen] = React.useState(false);
  const [openError , setOpenError] = React.useState(false);
  const [forgotPass , setForgotPass] = React.useState(false);
  const [fPassNo, setFpassNo] = React.useState('');
  const  [forgotOtp , setForgotOtp] = React.useState('');
  const [otp , setOtp] = React.useState('');
  const [newPass , setNewPass] = React.useState(false);
  const [newPassword , SetNewPassword] = React.useState('');
  const [ confirmPassword, setConfirmPassword ] = React.useState("");
  const [ handlePass , setHandlePass ] = React.useState(false);
  const  [ passDisable, setPassDisable ] = React.useState(true);
  const [ concent , setConcet ] = React.useState(true);
  const [check, setCheck] = React.useState(false);
  const [ openReg , setOpenReg ] = React.useState(false);
  const [ regLoad , setRegLoad ] = React.useState(false);
  const [ userPan , setUserPan ] = React.useState("");

  const [ forgetTkn , setforgetTkn ] = React.useState("");

  const [ mobCap , setMobCap ] = React.useState(true);

  const [ base64 , setBase64 ] = React.useState("");
  const [ baseKey , setBaseKey ] = React.useState("");
  const [ captcha , setCaptcha ] = React.useState("");
  const [ snackMessage , setSnackMessage ] = React.useState('');
  const [ snackOpen , setSnackOpen ] = React.useState(false); 
  const [ userPinCode , setUserPincode ] = React.useState("");

  const [ regCaptcha , setRegCaptcha ] = React.useState("");
  const [ forgotCaptcha , setForgotCaptcha ] = React.useState("");

  const [ register , setRegister ] = React.useState({
    name:"",
    phone:"",
    email:"",
    address:"",
    password:""
  });

  const [ regName , setRegName ] = React.useState("");
  const [ regPhone , setRegPhone ] = React.useState("");
  const [ regEmail , setRegEmail ] = React.useState("");
  const [ regDate , setRegDate ] = React.useState("");
  const [ regAddress , setRegAddress ] = React.useState("");
  const [ regPassword , setRegPassword ] = React.useState("");
  const [ reqError , setRegError ] = React.useState(false);

  const [ userState , setUserState ] = React.useState("");
  const [ userCity , setUserCity ] = React.useState("");

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));  

  const handleRegName = (e) => {
    const value = e.target.value
    const regex = /^[A-Za-z\s]+$/;

    if ((regex.test(value) || value === "") && value.length <= 30) {
     setRegName(value)
    } else if (value.length > 20) {
      console.log("Invalid input for fatherName: Maximum length is 20 characters.");
    } else {
      console.log("Invalid input for fatherName: Only alphabets are allowed.");
    }  }

  const handleRegPhone = (e) => {
    const mobileNumberRegex = /^[0-9]{0,10}$/;
    const value = e.target.value.trim();
    console.log("value : ",value, "  ",mobileNumberRegex.test(value));
    console.log("manual test : ",mobileNumberRegex.test("789456123"))
    if(mobileNumberRegex.test(value) && value.length <= 10 ){
      setRegPhone(value)
    }
  }

  const handleRegEmail = (e) => {
    const value = e.target.value.trim(); // Trim whitespace
    const checkEmail = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if(checkEmail.test(value)){
      setRegEmail(value)
    }else{
      console.log(value);
    }
  }

  const handleRegister = (e) => {
    const { name, value } = e.target;

    setRegister((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  console.log("concent data : ",concentData);

  const handleOpenReg = () => {
    setOpenReg(false);
    generateCaptcha();
  }


  const handleConcentClose = () => {
    
    Cookies.remove("demoCAuth");

    setConcet(false);
  }

  const handleForgotPasssClose = () => {
    setForgotPass(false)
  };

  const handleNewPass = () => {
    setNewPass(false)
  }

  const handleForgotOtpClose = () => {
    setOtp(false)
  }

  // const submitNewPass = async() => {
  //   const fetchData = await fetch("http://siddhi.local/b2c_live/public/api/setPassword", {
  //     method:"POST",
  //     headers:{
  //       "Content-Type":"application/json", 
  //     },
  //     body:JSON.stringify({"phone_no":fPassNo, "password": newPass })
  //   });
  //   console.log({"phone_no":fPassNo, "password": newPassword });
  //   const json = await fetchData.json();
  //   console.log("newPass json : ",json);
    
  //   if(json){
  //     if(json.Status === "Success"){
  //       // setNewPass(true);
  //       setNewPass(false);
  //       setOpen(true);
  //       setJsonSuccess(json.Message.Msg);
  //       setEmail(fPassNo);
  //       setPassword(newPassword)
  //       console.log({ "phone":fPassNo , "password" : password })
  //       //getNextAccess(fPassNo, newPassword);
  //     }
  //     else if(json.Status === "Error"){
  //       setOpenError(true)
  //       setJsonError(json.Message);
  //     }
  //   }
  // }

  const submitNewPass = async() => {

    const url = process.env.REACT_APP_UAPI_URL

    const fetchData = await fetch(`${url}/api/setPassword`,{
      method:"POST",
      headers:{
        "Content-Type":"application/json", 
        "Accept":"application/json",
        "Authorization":`Bearer ${forgetTkn}`
      },
      body:JSON.stringify( fPassNo.length === 10 ? {"phone_no":fPassNo, "password": newPassword } : {"phone_no":email, "password": newPassword } )
    })

    const json = await fetchData.json();
    console.log("otp json : ",json);
    
    if(json){
      if(json.status === "success" && json.statuscode === 200){
         setNewPass(true);
        setNewPass(false);
        setOpen(true);
        setJsonSuccess(json.Message);
        setEmail("");
        setPassword("")
        console.log({ "phone":fPassNo , "password" : password });
        setTimeout(() => {
          window.location.reload();
        },1000)
        //getNextAccess(fPassNo, newPassword);
      }
      else if(json.status === "Error"){
        setOpenError(true)
        setJsonError(json.message);
      }
    }
  }

  const submitFOtpNo = async() => {

    const url = process.env.REACT_APP_UAPI_URL
    const fetchData = await fetch(`${url}/api/authenticate`, {
      method:"POST",
      headers:{
        "Content-Type":"application/json", 
      },
      body:JSON.stringify( fPassNo.length === 10 ? {"phone_no":fPassNo, "password": otp } : {"phone_no":email, "password": otp } )
    })

    const json = await fetchData.json();
    console.log("otp json : ",json);
    
    if(json){
      if(json.status === "success" && json.statuscode === 200 ){
        setNewPass(true);
        setForgotOtp(false);
        setforgetTkn(json?.data?.token);
      }
      else if(json.status === "error"){
        setOpenError(true)
        setJsonError(json.message);
      }
    }
  }


  const submitFPassNo = async() => {
    const url = process.env.REACT_APP_UAPI_URL
    const fetchData = await fetch(`${url}/api/forgotPassword`, {
      method:"POST",
      headers:{
        "Content-Type":"application/json", 
      },
      body:JSON.stringify({ "phone_no": fPassNo, "key":baseKey, "captcha":forgotCaptcha })
    })

    const json = await fetchData.json();
    console.log("fogot pass json : ",json);
    
    if(json){
      if(json.status === "success" && json.statuscode === 200  ){
        setOpen(true);
        setJsonSuccess(json.Message);
        setForgotPass(false);
        setForgotOtp(true);
      }
      else if(json.status === "error"){
        setOpenError(true)
        setJsonError(json.message);
        generateCaptcha();
        setForgotCaptcha("");
      }
    }

  }

  const handleCheck = (e) => {
    console.log("e : ",e );
    setCheck(e.target.checked);
  }

  const handleSnackClose = () => {
    setSnackOpen(false);
  }


  const handleClose = () => {
    setOpen(false)
  };

  const handleErrorClose = () => {
    setOpenError(false)
  }
  
  const navigate = useNavigate();

  const checkButton = password.length < 4 && password.length >= 1
  const isProduction = process.env.NODE_ENV === 'production';

  console.log("node env : ",process.env.NODE_ENV);

  async function getNextAccess(  ){
    
    setProgress(true)
    try{
      
      const url = process.env.REACT_APP_UAPI_URL;
      const apiUrl = `${url}/api/login`;
      const method = "POST";
      const bodyData = {"phone_no":email, "password":password}

      console.log("url : ",url);

     const json = await HandleApi({"apiUrl":apiUrl, "method":method, "bodyData":bodyData});

      console.log("response data : ",json);
      
      setProgress(false)
  
      if(json){
        if(json.errors === 'Invalid Input' || json.errors === "Invalid Credentials" ){
          setOpenError(true)
          setJsonError(json.errors)
        } 
        
        if(json.status === 'success'){
          console.log()
          const token = json.data.access_token;
          console.log("token : ",token);
          // {
          //   process.env.NODE_ENV === "development" ? 
          //   Cookies.set("demoCAuth",token) :  
           Cookies.set("demoCAuth",token);
          //  }
          // const decodedQR = decodeURIComponent(json?.data?.QR);
          // console.log("decoded qr : ",decodedQR);
          // Cookies.set("demoCQr", decodedQR);
          // const decodedMsg = decodeURIComponent(json?.data?.kyc_msg)
          // Cookies.set("demoCMsg",decodedMsg);
          navigate('/validateUser');
        }
       if(json.status === "error"){
          setOpenError(true)
          setJsonError(json.message);
          setPassword("");
        }
      }
    }catch(err){
      console.log(error);
      setProgress(false)
    }

  }

  const handleProceed = async(e) => {
    e.preventDefault();

    if(mobCap === true){
      verifyUser()
    }

    else if(passDisable === false ){
      getNextAccess()
    }
  }

  const verifyUser = async() => {

    setProgress(true)

    try{

      const url = process.env.REACT_APP_UAPI_URL;
      const apiUrl = `${url}/api/verifyMobile`;
      const method = "POST";
      const bodyData = { "phone_no":email.toString(), "key":baseKey, "captcha":captcha  }
  
      const json = await HandleApi({"apiUrl":apiUrl, "method":method, "bodyData":bodyData})
  
      console.log("json data : ",json);
      setProgress(false);

      if(json){

        if(json.status === "success"){
  
          if(json.message === "password"){
            console.log("entered here pass")
            setPassDisable(false);
            setMobCap(false);
            setSnackOpen(true);
            setSnackMessage("Please Enter Password");
          }
          
          if(json.message === "otp"){
            console.log("entered here otp")
            setForgotOtp(true);
            
            // setPassDisable(false);
          }

          
        }
        if(json.status === "error"){
          setOpenError(true)
          setJsonError(json.message);
          generateCaptcha();
          setCaptcha("");

          if(json?.data?.register)
            {
              setTimeout(() => {
                setOpenReg(true);
              },800)
            }  

        }
      }

    }catch(err){
      console.log(err)
    }

  }

  const generateCaptcha = async() => {

    try{
      const url = process.env.REACT_APP_UAPI_URL;
      const apiUrl = `${url}/api/captcha`;
      const method = "GET";
  
      const json = await HandleApi({"apiUrl":apiUrl, "method":method })
  
      console.log("captcha json : ",json);
  
      if(json){
        if(json.status === "success"){
            setBase64(json?.data?.image);
            setBaseKey(json?.data?.key);
        }
  
       else if(json.status === "error"){
        window.alert(json.message);
        setCaptcha("");
       }
      }
    }catch(err){
      console.log(err)
    }
  }

  React.useEffect(() => {
    generateCaptcha();
  },[])

  // React.useEffect(() => {
  //   {
  //     if(email.length === 10 && captcha?.length === 6 ){
  //       verifyUser();
  //     }
  //   }
  // },[email.length === 10 && captcha?.length === 6 ])

  const checkData = async(e) => {

    e?.preventDefault();

    if(regPhone?.length !== 10 ){
      setOpenError(true);
      setJsonError("Mobile Number must Be  10 Digit.");
      return;
    }

    else if(regPassword?.length < 4 ){
      setOpenError(true);
      setJsonError("Password Must Be Atleast 4 Character.");
      return;
    }

    try{
      
      setRegLoad(true);
  
      const url = process.env.REACT_APP_UAPI_URL;
      const apiUrl = `${url}/api/cust_register`;
      const method = "POST";
      const bodyData = {
        "name":regName,
        "phone_no":regPhone,
        "email":regEmail,
        "password":regPassword,
        "dob":regDate,
        "address":regAddress,
        "key":baseKey,
        "captcha":regCaptcha,
        "city":userCity,
        "state":userState,
        "pincode":userPinCode
        }
  
      const json = await HandleApi({"apiUrl":apiUrl, "method":method, "bodyData":bodyData});
      setRegLoad(false);
      if(json){
          if(json.status === "success"){
              setOpen(true);
              setJsonSuccess(json.message);
              setRegName("");
              setRegAddress("");
              setRegDate("");
              setRegEmail("");
              setRegAddress("");
              setRegPhone("");
              setRegPassword("");
              setRegCaptcha("");
              setUserCity("");
              setUserState("");
              setUserPincode("");
              generateCaptcha();
              setTimeout(() => {
                setOpenReg(false);
              },1200)
  
          }else if(json.status === "error"){
            setOpenError(true);
            setJsonError(json.message);
            generateCaptcha();
            setRegCaptcha("");
          }
      }
    }catch(err){

    }


  }

  const checkEmail = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(regEmail);

  const handleRegisterOpen = async() => {
    setOpenReg(true);
    generateCaptcha();
  } 

  const handleForgotPass = () => {
    setForgotPass(true);
    generateCaptcha();
  }


  const handlePan = (e) => {
    const value = e.target.value.toUpperCase();
    const regexp = /^([a-zA-Z]([a-zA-Z]([a-zA-Z]([P]([a-zA-Z]([0-9]([0-9]([0-9]([0-9]([a-zA-Z])?)?)?)?)?)?)?)?)?)?$/;
    if(regexp.test(value) == true){
      setUserPan(value);
    }else{
      console.log("invalid input");
    }
  };

  const handlePinCodeChange = (e) => {
    const input = e.target.value;
  
    // Allow only numeric input and restrict to 6 digits
    if (/^\d*$/.test(input) && input.length <= 6) {
      setUserPincode(input); // Update the state with valid input
    } else {
      console.log("Invalid input or exceeds 6 digits");
    }
  
    // Optional: Add specific logic when input reaches exactly 6 digits
    if (input.length === 6 && /^\d{6}$/.test(input)) {
      console.log("Valid 6-digit pincode:", input);
    }
  };
  

  console.log("user pin code : ",userPinCode);

  
  const handleMobileNumber = (e) => {
    
    const value = e.target.value.replace(/\D/g, ""); // Remove any non-numeric characters

    if(value?.length <= 10){
      setEmail(value)
    }else{
      console.log("inalid email = ",value)
      return;
    }

  }


  
  return (
    <Box  sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:{lg:"row", xs:"column"}, height:"100vh", background:{xs: "linear-gradient(to top, white, #71b4eb )", lg:"white" } }} >


    <Box sx={{  height:{lg:"100vh", xs:"0vh"} , width:{lg:"55%", xs:"100%"} , position:'relative', overflow:'hidden', backgroundColor:'#3243a6db' , borderRadius:'0px 0px 520px 0px ' }} >
      {
        isXs ? null: 
        <Typography sx={{ position:'relative', textAlign:'center', fontFamily:"montserrat", marginTop:'5%', fontWeight:600, color:'white', fontSize:30, textShadow:'0px 0px 2.5px white' }} >Welcome To eezib Customer Portal</Typography>
      }
            <Box component='img' src={handCard} sx={{ zIndex:1, position:'fixed', width:{lg:600 , md:0 , sm:0, xs:0}, bottom:0, }} />
    </Box>



    <Box  sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', width:{lg:"55%", xs:"90%"}, mt:"-2rem"  }} >
      {
        isXs ? null : 
    <Typography sx={{ position:"absolute", top:{lg:"10%", md:"6%", sm:"4%", xs:"4%" }, color:"#ebebeb", fontFamily:"montserrat", fontWeight:600, fontSize:{xs:"1.5rem", lg:0}, textShadow:"0px 0px 10px #008cff" }} > Eezib Customer Portal </Typography>
      }
        <form onSubmit={handleProceed} >
      <Paper elevation={12} sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:{lg:3.5, xs:3}, padding:'2rem 2rem', borderRadius:5 }} >
        <Box component='img' src={imageLogo} onClick={() => window.open(`${process.env.REACT_APP_UAT_URL}`) } sx={{ width:{lg:150, xs:120}, cursor:'pointer' }} />
       {
        mobCap ? 
       <>
        <TextField
          name="number"
          autoComplete="off"
          type='number'
          error={email.length > 0 && email.length !== 10} // Adjust error condition
          helperText={
            email.length > 0 && email.length !== 10
              ? "Please enter a valid number"
              : null
          }
          onChange={(e) => handleMobileNumber(e)}
          value={email} 
          label="Enter Mobile No."
          variant="standard"
          size="medium"
          InputLabelProps={{
            style: { fontFamily: "Poppins", fontWeight: 300 },
          }}
          inputProps={{
            maxLength: 10,
            style: { fontFamily: "Poppins" },
          }}
          sx={{
            width: { lg: 360, md: 320 },
            borderRadius: 150,
            "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
              WebkitAppearance: "none",
              margin: 0,
            },
            "input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
        />


            <Box sx={{ mt:-0.5, display:'flex', alignItems:'center', justifyContent:'space-evenly', gap:2 }} >
              <img src={`data:image/png;base64,${base64}`} alt='captcha' style={{ border:'1px solid #bababa', borderRadius:5 }} />
              <ReplayIcon onClick={generateCaptcha} sx={{ color:"white", backgroundColor:"#467fdb", borderRadius:1, fontSize:'1.2rem', cursor:'pointer', boxShadow:'0px 3px 4px 0px gray' }} />
            </Box>

            <OutlinedInput sx={{ mt:-2, fontFamily:'Poppins', border:'1px solid white' }} size="small" value={captcha} onChange={(e) => setCaptcha(e.target.value)} placeholder='captcha' InputProps={{ style:{ fontFamily:'Poppins', borderRadius:'15px'  } }} />
            </> : null
          }

            {
              passDisable ? null : 
        <TextField disabled={passDisable} autoFocus type='password' name="password" error={ checkButton } helperText={ password.length >= 1 && password.length < 4 ? "minimum length must be 4" : null } onChange={(e) => setPassword(e.target.value)} value={password} label="Enter Password" variant='standard' size='medium' InputLabelProps={{ style:{ fontFamily:'Poppins'} }} inputProps={{ style:{fontFamily:'Poppins'} }} sx={{ width:{lg:360 , md:320} , borderRadius:150  }} />
            }
        <Box sx={{ display:'flex', alignItems:"center", justifyContent:'space-between', width:"100%" }} >
        <Typography onClick={ handleForgotPass }  sx={{ fontFamily:'Poppins', fontWeight:400 , color:'#0d55ff', fontSize:12, marginTop:-2 , marginBottom:-2, cursor:'pointer', marginLeft:'auto' }} >Forgot password ?</Typography>
        </Box>
     
        {
          progress ? <CircularProgress/> : 
          <Button type='submit' disabled={mobCap ? email.length !== 10 || captcha?.length !== 6 : password.length <= 3 } variant='contained' sx={{ width:{lg:360 , md:320 ,xs:260 }, fontWeight:500, fontFamily:'Poppins', letterSpacing:1.5 }} >proceed</Button>
        }
          <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly', gap:1, width:"100%", mt:-2    }} >
            <Typography onClick={() => window.open(`${process.env.REACT_APP_UAT_URL}/login`)} sx={{ fontFamily:"Poppins", fontWeight:500, fontSize:"0.8rem", size:"#4a576b", cursor:'pointer', mt:-1, mb:-1, color:'#7a7a7a' }} >Corporate Login</Typography>
            <Divider orientation="vertical" variant="middle" flexItem sx={{ height:"1rem" }} />
            <Typography onClick={handleRegisterOpen} sx={{ fontFamily:"Poppins", fontWeight:500, fontSize:"0.8rem", size:"#4a576b", cursor:'pointer', mt:-1, mb:-1, color:"#7a7a7a" }} >Register Customer</Typography>
          </Box>

        <Box elevation={8} sx={{ display:'flex', alignItems:'center', justifyContent:'space-around', padding:0.5,  width:"100%" }} >
        <FacebookIcon onClick={() => window.open('https://www.facebook.com/eezibindia/','')} sx={{ fontSize:'2.2rem', color:'#0043d4', cursor:'pointer' }} />
        <TwitterIcon onClick={() => window.open('https://twitter.com/i/flow/login?redirect_after_login=%2Feezibindia', "")} sx={{ fontSize:'2.2rem', color:"#00bfff" , cursor:'pointer' }} />
        <InstagramIcon onClick={() =>  window.open("https://www.instagram.com/eezibindia/", "") } sx={{ fontSize:'2.2rem' , cursor:'pointer', color:"#d573ff" }} />
      </Box>
      
        {/* <Link to="https://uat.eezib.in/register"  >Get Your Eezib Corporate Account Now</Link> */}

      {/* <Box elevation={8} sx={{ display:'flex', alignItems:'center', justifyContent:'space-around', padding:0.5,  width:"100%" }} >
        <FacebookIcon onClick={() => window.location.replace('https://www.facebook.com/eezibindia/')} sx={{ fontSize:'2.2rem', color:'#0043d4', cursor:'pointer' }} />
        <TwitterIcon onClick={() => window.location.replace('https://www.instagram.com/eezibindia/')} sx={{ fontSize:'2.2rem', color:"#00bfff" , cursor:'pointer' }} />
        <InstagramIcon sx={{ fontSize:'2.2rem' , cursor:'pointer', color:"#d573ff" }} />
      </Box> */}

        {/* <Typography onClick={() => window.open("https://client.eezib.in/register", "")} sx={{ fontFamily:'Poppins', fontWeight:400 , color:'gray', fontSize:15, marginTop:-1.5 , marginBottom:-1.5, cursor:'pointer' }} >create corporate account</Typography> */}
      </Paper>
      </form>
    </Box>

    <Snackbar
    anchorOrigin={{ vertical:"top", horizontal:"right" }}
    open={open}
    onClose={handleClose}
    autoHideDuration={2500}
    >
      <Alert severity='success' >{jsonSuccess}..</Alert>
    </Snackbar>

    <Snackbar
    anchorOrigin={{ vertical:"top", horizontal:"right" }}
    open={openError}
    onClose={handleErrorClose}
    autoHideDuration={2500}
    >
      <Alert severity='error' >{jsonError}..</Alert>
    </Snackbar>


    <Dialog
        open={forgotPass}
        onClose={handleForgotPasssClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
      >
        <CloseIcon onClick={() => setForgotPass(false)} sx={{ marginLeft:'auto', color:'white', backgroundColor:'red', fontSize:"1.1rem", cursor:'pointer' }}  />

        <DialogContent sx={{ backgroundColor:'white', margin:0, padding:1, width:{lg:"20rem", xs:"16.5rem" } }} >
          <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', padding:1 }} >
            <Box>
              <Box component='img' src={forgetPass} sx={{ width:{lg:"15rem", xs:"12rem"} }} />
            </Box>

            <Box>
              <Typography sx={{ fontFamily:'montserrat', fontWeight:600, fontSize:{lg:'1.2rem', xs:'0.9rem'}, textAlign:'center' }} > Forgot Password !</Typography>
              <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:1 }} >
              <Typography sx={{ textAlign:'center', fontFamily:'montserrat', fontWeight:500, fontSize:{lg:'0.8rem', xs:"0.7rem"}, marginTop:'1rem', textTransform:"capitalize", color:'#a4a6a5' }} >please enter registered mobile number</Typography>
              <TextField autoComplete='new-password' type="number" helperText={ email.length >= 1 && (fPassNo.length < 10 ||  fPassNo.length < 10 )? "Enter Valid Mobile Number" : null } fullWidth label="enter mobile number" value={fPassNo} onChange={(e) => forgetPass ? setFpassNo( e.target.value) : setEmail(e.target.value) } inputProps={{ style:{fontFamily:'Poppins' }, autoComplete:"new-password" }} InputLabelProps={{ style:{ fontFamily:'Poppins', textTransform:"capitalize"} }} sx={{ marginTop:"0.6rem",  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
              WebkitAppearance: "none",
              margin: 0,
            },
            "input[type=number]": {
              MozAppearance: "textfield",
            } }} />

                <Box>
              <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', gap:1, width:'100%' }} >

                  <img src={`data:image/png;base64,${base64}`} alt='captcha' />
                  <ReplayIcon onClick={generateCaptcha} sx={{ color:"white", backgroundColor:"#467fdb", borderRadius:1, fontSize:'1.2rem', cursor:'pointer' }} />
                </Box>
                <OutlinedInput fullWidth size='small' value={forgotCaptcha} onChange={(e) => setForgotCaptcha(e.target.value) } placeholder="Enter captcha" sx={{ fontFamily:'Poppins', mt:1 }} />
              </Box>

              <Button onClick={() => submitFPassNo()} disabled={ fPassNo.length > 10 || forgotCaptcha.length !== 6 || fPassNo.length < 10  } fullWidth sx={{ fontFamily:'montserrat', fontWeight:500, mt:'1rem' }} variant='contained' >get otp</Button>
              </Box>
            </Box>

          </Box>
        </DialogContent>
       
      </Dialog>



      <Dialog
        open={forgotOtp}
        onClose={handleForgotOtpClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
      >
        <CloseIcon onClick={() => setForgotOtp(false)} sx={{ marginLeft:'auto', color:'white', backgroundColor:'red', fontSize:"1.1rem", cursor:'pointer' }}  />

        <DialogContent sx={{ backgroundColor:'white', margin:0, padding:1, width:{lg:"20rem", xs:"17rem"} }} >
          <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', padding:1 }} >
            <Box>
              <Box component='img' src={otpImage} sx={{ width:"17rem" }} />
            </Box>

            <Box>
              <Typography sx={{ fontFamily:'montserrat', fontWeight:600, fontSize:'1.2rem', textAlign:'center' }} > Please enter OTP </Typography>
              <Box>
              <Typography sx={{ textAlign:'center', fontFamily:'montserrat', fontWeight:500, fontSize:{lg:'0.8rem' ,xs:"0.65rem"}, marginTop:'1rem', textTransform:"capitalize", color:'#a4a6a5' }} >enter OTP sent to registered mobile number</Typography>
              <TextField fullWidth type='number' label="enter OTP" value={otp} onChange={(e) => setOtp( e.target.value )} inputProps={{ style:{fontFamily:'Poppins' } }} InputLabelProps={{ style:{ fontFamily:'Poppins', textTransform:"capitalize"} }} sx={{ marginTop:"0.6rem" }} />
              <Button onClick={() => submitFOtpNo()} disabled={ !otp } fullWidth sx={{ fontFamily:'montserrat', fontWeight:500, mt:'1rem' }} variant='contained' >Submit</Button>

              </Box>
            </Box>

          </Box>
        </DialogContent>
       
      </Dialog>



      <Dialog
        open={newPass}
        // open={true}
        onClose={handleNewPass}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
      >
        <CloseIcon onClick={() => setNewPass(false)} sx={{ marginLeft:'auto', color:'white', backgroundColor:'red', fontSize:"1.1rem", cursor:'pointer' }}  />

        <DialogContent sx={{ backgroundColor:'white', margin:0, padding:1, width:{lg:"20rem", xs:"16.5rem" } }} >
          <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', padding:1 }} >
            <Box>
              <Box component='img' src={newPassImage} sx={{ width:"17rem" }} />
            </Box>

            <Box>
              <Typography sx={{ fontFamily:'montserrat', fontWeight:600, fontSize:{lg:'1.2rem', xs:"1rem" } , textAlign:'center' }} > Please Enter New Password </Typography>
              <Box>
              <Typography sx={{ textAlign:'center', fontFamily:'montserrat', fontWeight:500, fontSize:'0.8rem', marginTop:'1rem', textTransform:"capitalize", color:'#a4a6a5' }} >enter your new password here</Typography>
              <TextField size='medium' type='password' fullWidth label="Password" value={newPassword} onChange={(e) => SetNewPassword( e.target.value )} inputProps={{ style:{fontFamily:'Poppins' } }} InputLabelProps={{ style:{ fontFamily:'Poppins', textTransform:"capitalize"} }} sx={{ marginTop:"0.6rem" }} />
              <TextField size='medium' type='password' fullWidth error={ confirmPassword !== "" && confirmPassword!== newPassword   } helperText={ confirmPassword !== newPassword && confirmPassword !== "" ? "Password Mismatch." : null } label="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword( e.target.value )} inputProps={{ style:{fontFamily:'Poppins' } }} InputLabelProps={{ style:{ fontFamily:'Poppins', textTransform:"capitalize"} }} sx={{ marginTop:"0.6rem" }} />
              <Button onClick={() => submitNewPass()} disabled={ confirmPassword !== newPassword || !newPassword || !confirmPassword } fullWidth sx={{ fontFamily:'montserrat', fontWeight:500, mt:'1rem' }} variant='contained' >Submit</Button>

              </Box>
            </Box>

          </Box>
        </DialogContent>
       
      </Dialog>


      <Dialog
        open={concent}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "550px",  // Set your width here
            },
          },
        }}
      >
        {/* <CloseIcon onClick={() => setConcet(false)} sx={{ marginLeft:'auto', color:'white', backgroundColor:'red', fontSize:"1.1rem", cursor:'pointer' }}  /> */}

        <DialogContent>


            <Typography sx={{ fontFamily:"montserrat", fontWeight:500, textAlign:'center', textTransform:'capitalize', fontSize:{lg:'1.2rem', xs:"0.75rem"} }} >Welcome to eezib customer portal</Typography>
              <Divider  variant="middle" sx={{ margin:"0.6rem" }} />
            {
            concentData?.map((row) => (
              <Box>

                <Box>  

                <Box sx={{ display:"flex", gap:1 }} >
                  
                <Checkbox sx={{ width:"1.5rem" ,'& .MuiSvgIcon-root': { fontSize:{lg:24, xs:22} } }} onChange={handleCheck} color='primary' checked={check} inputProps={{ 'aria-label': 'controlled' }} />
                <Typography sx={{ fontFamily:"Poppins", fontWeight:300, textTransform:"capitalize", fontSize:{lg:"1rem", xs:"0.7rem" } }} >{row.Message.concent}</Typography>
                </Box>
               
                </Box>
                
                <Typography sx={{ fontFamily:"Poppins", fontWeight:400, textTransform:"capitalize", color:"#474747", textAlign:'center', marginTop:"1.3rem", fontSize:{lg:"1rem", xs:"0.7rem" } }} >check out <span onClick={() => window.open(`${process.env.REACT_APP_UAT_URL}/contactus`, "")} style={{ color:"#5925c2", textDecoration:'underline', cursor:'pointer' }} > FAQ/SUPPORT </span> - Eezib</Typography>

                {/* <Box>
                  {
                    row.consentLink !== null ? <Typography onClick={() => window.open(row.consentLink, "")} sx={{ fontFamily:"Poppins", fontWeight:400, marginTop:"0.5rem", textAlign:'center', mt:2 }} > Check Out <span style={{ fontFamily:"Poppins", fontWeight:500, textTransform:"capitalize", color:"#43007d", cursor:"pointer", textDecoration:'underline' }}>terms & conditions</span>  </Typography> : null   
                  }
                </Box> */}

                  <Button fullWidth disabled={ !check } onClick={handleConcentClose}  variant='contained' sx={{ marginTop:1, fontFamily:"montserrat", fontWeight:400 }} > continue  </Button>
             
              </Box>
            ))
          } 
        </DialogContent>
       
      </Dialog>


      <Dialog
        open={openReg}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleOpenReg}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",  // Set your width here
            },
          },
        }}
      > 
        <CloseIcon onClick={() => setOpenReg(false)} sx={{ position:"absolute", right:2, color:'red', cursor:"pointer" }} />
        <DialogTitle sx={{ textAlign:'center' }} >{"Register Yourself"}</DialogTitle>
        <Divider variant="middle" />

        <DialogContent sx={{ display:'flex', alignItems:'center', justifyContent:"space-evenly", flexDirection: isXs ? "column" : "row" }} >
          <Box component="img" src={registerCostumer} sx={{ width: isXs ? "15rem":"21rem"  }} />

            <form onSubmit={checkData} >
          <Box sx={{ display:'flex', alignItems:'center', justifyContent:"center", flexDirection:"column", gap:1 }} >


            <Box sx={{ display:'flex', alignItems:'center', justifyContent:"center", gap:"1rem",  flexDirection: isXs ? "column" : "row" }} >
              <TextField type='name' required sx={{ width: isXs ? "15rem":"12rem"  }} label="Enter Name" name="name" value={regName} onChange={handleRegName} InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} />
              <TextField required sx={{ width: isXs ? "15rem":"12rem"  }} type="number" label="Enter Phone Number" value={regPhone} onChange={handleRegPhone} InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500, fontSize:'0.9rem' } }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} />
            </Box>

            <Box>
            <TextField required type="email" sx={{ width: isXs ? "15rem":"25rem" }} fullWidth  label="Enter Email" name="email" value={regEmail} onChange={(e) => setRegEmail(e.target.value)} InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} 
              error={ !regEmail && regEmail.length >= 1  } helperText={ !/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(regEmail) && regEmail.length >= 1 ? "enter valid email" : null}
            />
            </Box>

          <Box sx={{ display:'flex', alignItems:'center', justifyContent:"center", gap:"1rem", flexDirection: isXs ? "column" : "row" }} >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer required components={['DatePicker']}>
              <DatePicker label="Select DOB" 
              direction="up"
              onChange={(e) => setRegDate(moment(e?.$d).format("YYYY-MM-DD"))}      
              value={regDate ? dayjs(regDate) : null} // Convert regDate to dayjs object if not null
              name="date"
              format="YYYY-MM-DD"
              disableFuture={true}
              required
                sx={{
                '& .MuiInputBase-root': {
                  fontFamily: 'montserrat', fontWeight:500,
                },
                '& .MuiFormLabel-root': {
                  fontFamily: 'montserrat', fontWeight:500,
                },
                '& .MuiInputBase-input': {
                  fontFamily: 'montserrat', fontWeight:500,
                },
                width: isXs ? "15rem":"12rem" 
              }}  />
            </DemoContainer>
          </LocalizationProvider>

          <TextField required sx={{ width: isXs ? "15rem":"12rem" , mt:0.5 }} name="address" value={regAddress} onChange={(e) => setRegAddress(e.target.value)} label="Enter Address" InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500, fontSize:'0.9rem' } }} />
            </Box>

            <Box sx={{ display:'flex', alignItems:'center', justifyContent:"center", gap:"1rem", flexDirection: isXs ? "column" : "row" }} >

            
            <TextField required InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} value={userCity}
               onChange={(e) => {
                const value = e.target.value;
                // Regex to allow only alphabets and spaces
                if (/^[a-zA-Z\s]*$/.test(value) && value?.length <= 30 ) {
                  setUserCity(value);
                }
              }} 
             sx={{ width:isXs ? "15rem":"12rem" }} InputLabelProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} label="Enter City" />

            <TextField required InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} value={userState}
               onChange={(e) => {
                const value = e.target.value;
          
                if (/^[a-zA-Z\s]*$/.test(value) && value?.length <= 30 ){
                  setUserState(e.target.value);
                }
               }}
               sx={{ width:isXs ? "15rem":"12rem" }} InputLabelProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} label="Enter State" />


               

            </Box>

            <Box sx={{ display:'flex', alignItems:'center', justifyContent:"center", gap:"1rem", flexDirection: isXs ? "column" : "row" }} >

            <TextField required InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} value={userPinCode} onChange={handlePinCodeChange}  sx={{ width:isXs ? "15rem":"12rem" }} InputLabelProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} label="Enter Pin Code" />
          
            <TextField required sx={{ width: isXs ? "15rem":"12rem" , mt:0.5 }} name="password" value={regPassword} onChange={(e) => setRegPassword(e.target.value)} label="Enter Password" InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500, fontSize:'0.9rem' } }} />
            </Box>

            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', gap:2, mt:1 }}>
              <Box sx={{ gap:'0.5rem' }} >
            <img src={`data:image/png;base64,${base64}`} alt='captcha' />
              <ReplayIcon onClick={generateCaptcha} sx={{ color:"white", backgroundColor:"#669aed", borderRadius:1, fontSize:'1.2rem', cursor:'pointer' }} />
              </Box>
            <OutlinedInput required value={regCaptcha} onChange={(e) => setRegCaptcha(e.target.value)} sx={{ fontFamily:"Poppins" }} size='small' placeholder='enter captcha' />
            </Box>

                {
                  regLoad ? <CircularProgress /> :
            <Button fullWidth disabled={ regCaptcha?.length !== 6 } type='submit' variant="contained" sx={{ fontFamily:"montserrat", fontWeight:500 }} >Submit</Button>
                }


          </Box>
            </form>
        </DialogContent>
      </Dialog>



      {/* ************************ topup dialogue ******************** */}


          <Footer/>

          <Snackbar
        open={snackOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={1500}
        onClose={handleSnackClose}
        variant="success"
        // message={snackMessage}
        // action={action}
      >
        <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
          {snackMessage}
        </Alert>
      </Snackbar>

           <SnackBarSuccess open={openError} status="warning" message={jsonError} snackClose={() => setOpenError(false) } />

    </Box>
  )
}

export default Login