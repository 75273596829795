import React from 'react'
import { Box, Typography } from '@mui/material'
import notfoundImg from "../images/404.webp";
import Footer from '../pages/Footer';

const NotFound = () => {
  return (
    <Box className="notfound" sx={{ display:'flex', alignItems:'center', flexDirection:'column', minHeight:'100vh' }} >     
        <Box component="img" src={notfoundImg} sx={{ width:{lg:"25rem", md:"25rem", sm:"20rem", xs:"18rem" } }} />
        <Typography sx={{ fontFamily:'poppins', fontSize:{lg:"1.3rem", md:'1.3rem', sm:"1.3rem", xs:"0.9rem" }, textTransform:'capitalize', mt:8 }} >we couldn't find what your are searching for.</Typography>
        <Typography sx={{ fontFamily:'poppins', fontSize:{lg:"1.1rem", md:'1.1rem', sm:"1.1rem", xs:"0.9rem" } , textTransform:'capitalize', mt:0, color:'#4f4f4f' }} >try search again</Typography>
        <Footer/>
    </Box>
  )
}

export default NotFound