import { Box, Button, CircularProgress, Divider, FormControl, IconButton, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import React from 'react'
import Header from '../components/Header'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import eezib2 from "../images/eezib.png"
import moment from 'moment';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { HandleApi } from '../HandleApi';
import reportCard from "../images/reportCard.png";
import jsPDF from 'jspdf';
import DownloadIcon from '@mui/icons-material/Download';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SnackBarSuccess from '../components/SnackBarSuccess';
import imagePng from "../images/imagePng.png";
import * as XLSX from "xlsx";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DiscountIcon from '@mui/icons-material/Discount';
import CloseIcon from '@mui/icons-material/Close';

const Report = () => {

    const  [ startDate , setStartDate ] = React.useState("");
    const  [ endDate , setEndDate ] = React.useState("");
    const [ reportType , setReportType ] = React.useState();
    const [age, setAge] = React.useState('');
    const [ reportData , setRepotData ] = React.useState();
    const [ reportHeader, setReportHeader ] = React.useState();
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [ snackMessage , setSnackMessage ] = React.useState("");
    const [ snackOpen , setSnackOpen ] = React.useState(false);
    const [ snackSeverity , setSnackSeverity ] = React.useState("");
    const [ openDetails , setOpenDetails ] = React.useState(false);
    const [ orderData , setOrderData ] = React.useState();
    const [ mailLoading , setMailLoading ] = React.useState(false)
    
    const authAccess = Cookies.get("demoCAuth");

    const startIndex = (page -1)* rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const slicedData = reportData?.slice(startIndex, endIndex);

    console.log("sliced data : ",slicedData)

    console.log("Age : ",age);

    const handleDetailsClose = () => {
      setOpenDetails(false);
    }
    
    const handleChange = (event) => {
        console.log(event.target.value)
      setAge(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    

    const handleReport = async() => {
        try{
            const url = process.env.REACT_APP_UAPI_URL;
            const apiUrl = `${url}/api/b2cReportType`;
         
            
            const json = await HandleApi({"apiUrl":apiUrl, "authAccess":authAccess });
    
            console.log("json data : ",json);
    
            if(json){
                if(json.statuscode === 200){
                    setReportType(json.data.report_type);
                    setReportHeader(json.data.header);
                    window.scroll(0,250)
                }
            }
        }catch(err){
            console.log(err);
        }

    }

    React.useEffect(() => {
        handleReport();
    },[])


    const handleImageData = (data) => {
      console.log("image data : ",data.image_url)
    }

    
    const getReportData = async() => {
      
      const url = process.env.REACT_APP_UAPI_URL;
      const apiUrl = `${url}/api/getReport`;
      const method = "POST";
        const body =  {
            "id":age,
            "from":startDate,
            "to":endDate
          }
          
          const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess, "bodyData":body })
          console.log(json);
          
          if(json){
            if(json.statuscode === 200){
              window.scrollBy(0,350);
              setRepotData(json.data.report_data);
              const headerData = Object.values(json.data.header);
              console.log("header : ",headerData );
              setReportHeader(json.data.header);
            } 
            if(json.status === 'error'){
              setSnackMessage(json.message);
              setSnackOpen(true);
              setSnackSeverity("error");
              setReportHeader();
              setRepotData();
            }
          }
          
        }

        console.log("report data : ",reportData);
        console.log("report herader : ",reportHeader);

        const handelSnackClose = () => {
          setSnackOpen(false);
        }

        const generatePDF = () => {
          if (reportData?.length === 0) {
            alert("No data available to generate PDF");
            return;
          }
        
          const pdf = new jsPDF();
          const keys = reportHeader;
        
          // Set initial x and y position
          let xPos = 10;
          let yPos = 10;
        
          // Set cell width and height
          const cellWidth = 30;
          const minHeight = 8;
        
          // Set font size
          const fontSize = 5;
          pdf.setFontSize(fontSize);
        
          // Add headers
          keys.forEach((key, index) => {
            pdf.rect(xPos, yPos, cellWidth, minHeight, 'S');
            pdf.text(xPos + 1, yPos + 5, key);
            xPos += cellWidth;
          });
        
          // Reset x position and increment y position
          xPos = 10;
          yPos += minHeight;
        
          // Add data
          reportData?.forEach(data => {
            keys.forEach((key, index) => {
              const value = data[key] || '';
              const valueLines = pdf.splitTextToSize(value.toString(), cellWidth - 2);
              const cellHeight = Math.max(minHeight, valueLines.length * fontSize / 2); // Adjust for font size
              pdf.text(xPos + 1, yPos + 5, valueLines);
              pdf.rect(xPos, yPos, cellWidth, cellHeight, 'S');
              xPos += cellWidth;
            });
            xPos = 10;
            yPos += minHeight;
          });
        
          pdf.save('download.pdf');
        };



        const generateExcel = () => {
          if ( reportData.length === 0 || reportData.length === undefined ) {
            alert("No data available to generate Excel");
            return;
          }
        
          // Create a new workbook
          const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.json_to_sheet(reportData);
        
          // Add the worksheet to the workbook
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        
          // Write the workbook to a file
          XLSX.writeFile(workbook, 'download.xlsx');
        
          alert("Excel file generated successfully");
        };
        
        const handleMailData = async(data, orderId) => {


          const apiUrl = data.mailUrl;
          const method = "POST";
            const body =  {
                "id":data?.card_id
              }
              
              const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess, "bodyData":body })
              console.log(json);

              if(json){
                if(json.statuscode === 200){
                  setSnackOpen(true);
                  setSnackMessage(json.message);
                  setSnackSeverity("success");
          
                }
                if(json.status === "error"){
                  setSnackOpen(true);
                  setSnackMessage(json.message);
                  setSnackSeverity("error");
                }
              }

        }

        console.log( "report data length : ",reportData?.length);

        const handleOrderData = async(e) => {
          console.log("order data : ",e)
          const url = process.env.REACT_APP_UAPI_URL;
          const apiUrl = `${url}/api/getOrderData`;
          const method = "POST";
          const body =  {
            "order_id":e
            }
          
          const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess, "bodyData":body })
          console.log(json);

          if(json){
            if(json.status == "success"){
              setOrderData(json.data);
              setOpenDetails(true)
            }
             if(json.status === "error"){
              setSnackOpen(true);
              setSnackMessage(json.message);
              setSnackSeverity("error");
             }
          }

        }

        const handleMail = async(apiUrl, orderId) => {

          console.log("data : ", `${apiUrl}/${orderId}`);


          setMailLoading(true)

          const method = "GET";
          
          const json = await HandleApi({"apiUrl":`${apiUrl}/${orderId}`, "authAccess":authAccess, "method":method });

          setMailLoading(false)
          console.log(json);

          if(json){
            if(json.status == "success"){
              setSnackOpen(true);
              setSnackMessage(json.message);
              setSnackSeverity("success");
            }
             if(json.status === "error"){
              setSnackOpen(true);
              setSnackMessage(json.message);
              setSnackSeverity("error");
             }
          }
          
        }


    return (
    <Box className="topupContainer" sx={{ minHeight:'100vh' }} >
        <Header />
        <IconButton>
        </IconButton>
        <Box sx={{ display:'flex', alignItems:'center', flexDirection:'column' }} >
        <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly', mt:{lg:10, sm:10 , sm:5, xs:3 }, width:'100%', flexDirection:{lg:"row", md:"row", sm:"column", xs:"column"  }, mb:{lg:0, sm:0,  md:0, xs:10 } }} >

              <Box component="img" src={reportCard} sx={{ width:{lg:"35rem", md:"30rem", sm:"30rem", xs:"23rem" } }} />
            <Box className='nepo' sx={{  display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', padding:"1rem 2rem", marginTop:{lg:0 , md:0 , sm:5, xs:5} }} >
                    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:1 }} >

                    <Box component="img" src={eezib2} sx={{ width:"6rem" }} />

                    <FormControl fullWidth sx={{ marginTop:"1rem" }} >
                    <InputLabel sx={{ fontFamily:'Poppins' }}  id="demo-simple-select-label"> Report Type</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Report Type"
                    onChange={handleChange}
                    sx={{ fontFamily:'Poppins' }}
                    >
                        {
                            reportType?.map((data, index) => (
                                <MenuItem sx={{ fontFamily:'Poppins' }} key={index} value={data.id} >{data.name}</MenuItem>
                            ))
                        }

                    </Select>
                </FormControl>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker disableFuture={true} defaultValue={ dayjs(startDate)} error={false}  onChange={(e) => setStartDate(moment(e?.$d).format("YYYY-MM-DD"))}  format="YYYY-MM-DD" label="Select Start Date" sx={{
                        '& .MuiInputLabel-root': {
                        fontFamily: 'Poppins',
                        },
                    }} />
                    </DemoContainer>
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker disableFuture={true} defaultValue={ dayjs(endDate)} onChange={(e) => setEndDate(moment(e?.$d).format("YYYY-MM-DD"))}  format="YYYY-MM-DD" label="Select End Date" sx={{
                        '& .MuiInputLabel-root': {
                        fontFamily: 'Poppins',
                        },
                    }} />
                    </DemoContainer>
                    </LocalizationProvider>

                    </Box>
                    

                    <Button variant="contained" disabled={ !age || !startDate || !endDate } onClick={() => getReportData()} fullWidth sx={{ fontFamily:'poppins', fontWeight:400, mt:2 }} >submit</Button>
            </Box>
            
            </Box>
            {
              reportData?.length >= 1 ?  
        <TableContainer elevation={16} component={Paper} sx={{ marginTop:{lg:0 , md:0 , sm:5, xs:5} , width:"95%", mb:'5rem', mt:{md:'2.5rem' , lg:0, sm:"2.5rem", xs:"2rem" } }}>
            
                  <Table>
                    <TableHead>
                      <TableRow sx={{ backgroundColor:'#42a5f5' }} >
                        {reportHeader?.map((header, index) => (
                          <TableCell align='center' sx={{ color:"white", fontFamily:"Poppins", textTransform:"capitalize", fontSize:"0.7rem" }} key={index}>{header}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {slicedData?.map((row, rowIndex) => (
        <TableRow className="tableRow" key={rowIndex}>
          {reportHeader?.map((header, headerIndex) => (
            <TableCell  sx={{ fontFamily:"Poppins", color:"black", fontWeight:400, fontSize:"0.8rem", textAlign:'center' }} key={headerIndex}>
              {header === 'mailUrl' ? (
                <MailOutlineIcon sx={{ cursor:'pointer' }} onClick={() => handleMailData(row)  }  />
                // <a href={row[header]} target="_blank" rel="noopener noreferrer">
                //   {row[header]}
                // </a>
              ) :   header === 'image_url' ?  
              <Box component="img" onClick={() => handleImageData(row)} src={imagePng} sx={{ width:"2rem", borderRadius:'1rem', cursor:'pointer' }} /> 
              :
              header === "Details" ?
              // <Box sx={{ width:"1rem", fontSize:"1rem" }} dangerouslySetInnerHTML={{ __html: <Box component="img" src={row?.Details} /> }} />
              <Box component="img" onClick={() => handleOrderData(row?.order_id) } src={row.Details} sx={{ width:"2rem", cursor:"pointer" }} />
 : 
               (
                row[header]
              )}

        {/* { header === 'image_url' ? (
                // <MailOutlineIcon sx={{ cursor:'pointer' }} onClick={() => handleMailData(row)  }  />
                <Box component-="img" src={imagePng} sx={{ width:"2rem" }} />
                // <a href={row[header]} target="_blank" rel="noopener noreferrer">
                //   {row[header]}
                // </a>
              ) : (
                row[header]
              )} */}

               {/* || header === 'image_url' */}
            </TableCell>
          ))}
        </TableRow>
      ))}
                    </TableBody>
                  </Table>
                  
               

        {/* <Pagination
        style={paginationStyle}
        variant="outlined"
        color="primary"
        count={ Math.ceil(handleUserTable()?.length / 10)}
        page={page}
        onClick={() => window.scroll(0,10)}
        onChange={handleChangePage}
        sx={{ marginBottom:5 }}
          /> */}

    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:"1rem", mt:"1rem"  }} >

            <Pagination
                  count={Math.ceil(reportData?.length / 10)}
                  page={page}
                 onChange={handleChangePage}
                 sx={{ float:'right' }}
                /> 

                <Box sx={{ gap:1, padding:1, display:'flex', alignItems:"center", }} >
              {/* <Button onClick={() => generatePDF()}  sx={{ fontFamily:'montserrat', fontWeight:500 }} variant='outlined' size='small' endIcon={<DownloadIcon/>} >PDF</Button> */}
              <Button onClick={() => generateExcel()} sx={{ fontFamily:'montserrat', fontWeight:500 }} variant='outlined' size='small' endIcon={<DownloadIcon/>}  >download EXCEL</Button>
            </Box>
    </Box>
                </TableContainer> : null
            }


        </Box>
{/* 
        "Product_image": "https:\/\/b2c.eezib.in\/website\/assets\/img\/transcorp-front.png",
        "Product_name": "Eezib Transcorp Prepaid Virtual Card",
        "Order_id": "67480f0727523281204",
        "Order_type": "cards",
        "Order_amonut": 111,
        "Order_discount": 0,
        "Order_status": "Pending",
        "Order_date": "2024-11-28T06:35:25.000000Z */}

        <Dialog
        open={openDetails}
        onClose={handleDetailsClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseIcon onClick={handleDetailsClose} sx={{ position:'absolute', right:5 , top:5

        }} />
        <DialogContent sx={{  }} >

          
          <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:"column" }} >
            {/* <Box component="img" src={eezib2} sx={{ width:{lg:"6rem", md:"6rem", sm:"6rem", xs:"4rem" } }} /> */}
            &nbsp;
              <Box  sx={{ width:"100%", display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', mt:-3  }} >
                <Box component="img" src={orderData?.Product_image} sx={{ width:{lg:"18rem", md:"20rem", sm:"18rem", xs:"13rem" }, borderRadius:2.5 }} />     
                <Typography sx={{ fontFamily:"Poppins", fontWeight:400,  textAlign:"center", color:"#1442A8", mt:1, fontSize:{lg:"0.95rem" , sm:"0.95rem", sm:"0.95rem", xs:"0.7rem" }  }} >{orderData?.Product_name} </Typography>
                {/* <Typography sx={{ fontFamily:"poppins", fontWeight:400,  textAlign:"center", color:"#8E8F94", fontSize:"0.85rem", mt:1 }} >Order Id : {orderData?.Order_id} </Typography> */}
                {/* <Typography sx={{ fontFamily:"poppins", fontWeight:400,  textAlign:"center", color:"#8E8F94", fontSize:"0.85rem" }} >Order Date : {orderData?.Order_date} </Typography> */}

              </Box>

              <Divider sx={{ width:"100%", marginTop:'1rem', marginBottom:'0.5rem', borderStyle:'dashed' }} />

              <Box sx={{ width:"100%", display:'flex', alignItems:'center', justifyContent:'space-between' }} >

                <Typography sx={{ fontFamily:'poppins', fontWeight:300, fontSize:"0.85rem", color:'#A0A4A2' }} >Total:<span style={{ fontFamily:"poppins", fontWeight:400, fontSize:"1rem", color:'#32BA64' }} > ₹ {orderData?.Order_amonut} </span> </Typography>
                <Typography sx={{ fontFamily:'poppins', fontWeight:300, fontSize:"0.85rem", color:'#A0A4A2' }} >Status:<span style={{ fontFamily:"poppins", color: orderData?.Order_status === "Complete" ? "#32BA64" : "red" }} > {orderData?.Order_status} </span> </Typography>

              </Box>

              <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', gap:'1rem', marginRight:"auto", mt:"1rem" }} >

                  <Box className="reportBtn" sx={{  display:'flex', alignItems:'center', justifyContent:'center', p:0.5, color:'white', borderRadius:1 }} > <AccessTimeIcon sx={{ fontSize:"1.7rem" }} /> </Box>
                
                  <Box>
                    <label style={{ fontSize:"0.7rem", color:'#C0C0C0' }} >Order Date</label>
                    <Typography sx={{ fontFamily:'poppins', fontSize:"0.95rem", mt:-0.3, color:"#77777A" }} >{orderData?.Order_date}</Typography>
                  </Box>
              </Box>


                  <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', gap:'1rem', marginRight:"auto", mt:"1rem" }} >

                  <Box className="reportBtn" sx={{  display:'flex', alignItems:'center', justifyContent:'center', p:0.5, color:'white', borderRadius:1 }} > <LocalShippingIcon sx={{ fontSize:"1.7rem" }} /> </Box>
                
                  <Box>
                    <label style={{ fontSize:"0.7rem", color:'#C0C0C0' }} >Order ID</label>
                    <Typography sx={{ fontFamily:'poppins', fontSize:"0.95rem", mt:-0.3, color:"#77777A" }} >{orderData?.Order_id}</Typography>
                  </Box>
              </Box>


              <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', gap:'1rem', marginRight:"auto", mt:"1rem" }} >

              <Box className="reportBtn" sx={{ display:'flex', alignItems:'center', justifyContent:'center', p:0.5, color:'white', borderRadius:1 }} > <DiscountIcon sx={{ fontSize:"1.7rem" }} /> </Box>

              <Box>
                <label style={{ fontSize:"0.7rem", color:'#C0C0C0' }} >Discount</label>
                <Typography sx={{ fontFamily:'poppins', fontSize:"0.95rem", mt:-0.3, color:"#77777A" }} >{orderData?.Order_discount}</Typography>
              </Box>
              </Box>
              
              {
                orderData?.mailURL ? mailLoading ? <CircularProgress/> :  <Button onClick={() => handleMail(orderData?.mailURL, orderData?.Order_id ) } className="reportBtn" size="small" variant="contained" fullWidth  sx={{ fontFamily:"poppins", mt:3 }} >
                resend mail
              </Button> 
              :
              <Button onClick={() => handleDetailsClose() } className="reportBtn" size="small" variant="contained" fullWidth  sx={{ fontFamily:"poppins", mt:3 }} >
                close 
              </Button>
              }

              {/* <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly', width:"100%" }} >
                <Box sx={{ backgroundColor:"#E3E3E4", width:"40%", display:'flex', alignItems:'center', justifyContent:'center', padding:"2.8px 1.5px", borderRadius:'5px' }} >  
                  <Typography sx={{ fontFamily:"poppins", fontWeight:400 }} >Amount : ₹ {orderData?.Order_amonut}</Typography>
                </Box>

                <Box sx={{ backgroundColor:"#E3E3E4", width:"40%", display:'flex', alignItems:'center', justifyContent:'center', padding:"2.8px 1.5px", borderRadius:'5px' }} >  
                     <Typography sx={{ fontFamily:"poppins", fontWeight:400 }} >Discount : ₹ {orderData?.Order_discount}</Typography>
                </Box>
              </Box>

              <Box sx={{ marginTop:"1rem" }} >
                  <Typography sx={{ fontFamily:'poppins', fontWeight:700, fontSize:"2.2rem", color: orderData?.Order_status === "Complete" ? "#41C672" : "#AAABAA" }} >{orderData?.Order_status}</Typography>
                  </Box> */}
                  </Box>
        </DialogContent>
      </Dialog>

        <SnackBarSuccess open={snackOpen} message={snackMessage} snackClose={handelSnackClose} status={snackSeverity} />
        
    
    </Box>
)
}

export default Report