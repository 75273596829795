import React from 'react';
import { AppBar, Box, CircularProgress, Dialog, DialogContent, Divider, IconButton, TextField, Toolbar, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import moment from 'moment';
import { HandleApi } from '../HandleApi';
import Cookies from 'js-cookie';
import profileCard from "../images/profile.png";
import SnackBarSuccess from './SnackBarSuccess';



const ProfileSection = ({openProfile, handleProfileClose, Transition, eezibWhite, handleClose, name }) => {

    console.log("new profile details : ",{openProfile, handleProfileClose, Transition, eezibWhite, handleClose, name })
    
  const [userName , setUserName ] = React.useState(``);
  const [phoneNumber , setPhoneNumber ] = React.useState('');
  const [ userEmail , setUserEmail ] = React.useState('');
  const [ userDob , setUserDob ] = React.useState(null);
  const [ userAddress , setUserAddress ] = React.useState("");
  const [ userPan , setUserPan ] = React.useState(""); 
  const [ userCity , setUserCity ] = React.useState("");
  const [ userState , setUserState ] = React.useState("");
  const [ userPinCode , setUserPincode ] = React.useState(""); 
  const [isLoading , setIsLoading ] = React.useState(false);
  const [jsonError , setJsonError] = React.useState('');
  const [ jsonSError , setJsonSError ] = React.useState('');
  const [activateOpen , setActiveOpen] = React.useState(false);
  const [activateSOpen , setActiveSOpen] = React.useState(false);
  const [ profileProgress , setProfileProgress ] = React.useState(false);
  const [ status , setStatus ] = React.useState("");
  const [ isDisable , setIsDisable ] = React.useState(false);
  const [clientPan , setClientPan] = React.useState("");
  const [ clientFather , setClientFather ] = React.useState("");
  

  console.log("userPan : ",userPan);

  const handlePan = (e) => {
    const value = e.target.value.toUpperCase();
    
    if (value === "") {
      // Clear the input when the value is empty
      setClientPan("");
      return;
    }
  
    const regexp = /^([a-zA-Z]([a-zA-Z]([a-zA-Z]([P]([a-zA-Z]([0-9]([0-9]([0-9]([0-9]([a-zA-Z])?)?)?)?)?)?)?)?)?)?$/;
  
    if (regexp.test(value)) {
      setClientPan(value);
    } else {
      console.log("Invalid input");
    }
  };
  

  const authAccess = Cookies.get("demoCAuth");

  const submitUpdate = async() =>{

    setIsLoading(true);
    
  const url = process.env.REACT_APP_UAPI_URL;
  const apiUrl = `${url}/api/update_profile`;
  const method = "POST";
  const bodyData = {
    "name": userName,
    "email": userEmail,
    "dob": userDob ,
    "address": userAddress,
    "pan_no": userPan === "" || userPan === null ? clientPan : userPan,
    "city":userCity,
    "state":userState,
    "pincode":userPinCode
    }

    
    const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess, bodyData:bodyData });
    
    setIsLoading(false);

    if(json){
      if(json.statuscode === 200 ){
        setJsonSError(json.message);
        setActiveSOpen(true);
        setStatus("success");
        userProfile();
      }
      if(json.status === "error" ){
        setJsonSError(json.message);
        setActiveSOpen(true);
        setStatus("error")
      }
    }
    
    }

    const userProfile = async() => {

      setProfileProgress(true);
  
      const url = process.env.REACT_APP_UAPI_URL;
  
        // https://b2cuat.eezib.in/api/profile
  
      const apiUrl = `${url}/api/profile`;
      const method = "GET";
  
      const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess });
  
      setProfileProgress(false);
  
      if(json){
        if(json.statuscode === 200 ){
          setUserName(json.data.name);
          setPhoneNumber(json.data.phone_no);
          setUserEmail(json.data.email);
          setUserDob(json.data.dob);
          setUserAddress(json.data.address);
          setUserPan(json.data.pan_no);
          setUserCity(json.data.city);
          setUserState(json.data.state);
          setUserPincode(json.data.pincode);
        }
    
        if(json.status === "error "){
          setJsonError(json.message);
          setActiveOpen(true);
        }
      }
      }

      React.useEffect(() => {
        userProfile();
      },[openProfile]);

      const handlePinCodeChange = (e) => {
        const input = e.target.value;
        if (input.length <= 6) {
          setUserPincode(input);
        }
      };

      const handleName = (e) => {
        const value = e.target.value
          const regex = /^[A-Za-z\s]+$/;
      
          if ((regex.test(value) || value === "") && value.length <= 30) {
           setUserName(value)
          } else if (value.length > 20) {
            console.log("Invalid input for fatherName: Maximum length is 20 characters.");
          } else {
            console.log("Invalid input for fatherName: Only alphabets are allowed.");
          }
      }


  return (
    <Dialog
    fullScreen
    open={openProfile}
    onClose={handleProfileClose}
    TransitionComponent={Transition}
  >
    <AppBar sx={{ position: 'relative' }}>
      <Toolbar sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }}
>  
        <Box onClick={() => window.open(`${process.env.REACT_APP_UAT_URL}`) } component="img" src={eezibWhite} sx={{ width:"9rem", cursor:'pointer' }}   />

        <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', gap:'2rem' }} >

        <Typography sx={{  textTransform:'capitalize', fontSize:{lg:"1rem", sm:'1rem', md:"1rem", xs:"0.9rem" }, fontFamily:"montserrat", fontweight:500 }} variant="h6" component="div">
          {name}'s Profile
        </Typography>

        <IconButton
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{ gap:"2rem" }}
        >
          <CloseIcon onClick={() => handleProfileClose()} />
        </IconButton>
        </Box>
      </Toolbar>
    </AppBar>

    <DialogContent className="profilecomp" >
      <Box sx={{ display:'flex', alignItems:'center', flexDirection:{lg:'row', sm:'column', md:'row', xs:"column" } ,justifyContent:'space-evenly', marginTop:{lg:5, md:10, sm:10, xs:0}, gap:5 }} >

<Box component='img' src={profileCard} sx={{ width:{lg:"40rem", md:"38rem", sm:"35rem", xs:"25rem"} }} />
{
profileProgress ? <CircularProgress size="10rem" thickness={1.5} /> : 

<Box sx={{ backgroundColor:'white', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:"column" , borderRadius:3 }} >
<Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:"1.2rem", padding:1, mt:2 }} > Profile Details </Typography>
<Divider variant='middle' />
<Box sx={{  padding:3, display:'flex', alignItems:'center', justifyContent:'center', gap:2 , flexDirection:'column'}} >


<Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:{lg:'row', sm:'row', md:'row', xs:"column" }, gap:1  }}  >
    <TextField  value={userName} onChange={handleName} sx={{ width:"17rem" }} InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500, textTransform:'capitalize' } }} InputLabelProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} inputProps={{ style:{ textTransform:'capitalize' } }} label="Name">{name}</TextField>
    <TextField variant='filled' InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }}  value={phoneNumber} sx={{ width:"17rem" }} InputLabelProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} label="Phone Number" />

</Box>

<Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:{lg:'row', sm:'row', md:'row', xs:"column" }, gap:1  }} >
<TextField  InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} value={userEmail} onChange={(e) => setUserEmail(e.target.value)}  sx={{ width:"17rem" }} InputLabelProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} label="Email" />
{/* <TextField  InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} value={userDob} onChange={(e) => setUserDob(e.target.value)}  sx={{ width:"17rem" }} InputLabelProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} label="Date Of Birth" /> */}

<LocalizationProvider sx={{ width:"10rem" }} dateAdapter={AdapterDayjs}>
  <DemoContainer components={['DatePicker']}>
  <DatePicker disableFuture={true} defaultValue={ dayjs(userDob)} format="DD-MM-YYYY" onChange={(e) => setUserDob(moment(e?.$d).format("YYYY-MM-DD"))}  sx={{
        width: '17rem',
        '& .MuiInputBase-root': {
          fontFamily: 'montserrat', fontWeight:500,
        },
        '& .MuiFormLabel-root': {
          fontFamily: 'montserrat', fontWeight:500,
        },
                    '& .MuiInputBase-input': {
          fontFamily: 'montserrat', fontWeight:500,
        },
      }} label="DOB" />
  </DemoContainer>
</LocalizationProvider>

</Box>

<Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:{lg:'row', sm:'row', md:'row', xs:"column" }, gap:1  }}  >
<TextField InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} value={userPan == "" || userPan===null ? clientPan : userPan} onChange={ userPan == "" || userPan === null ? handlePan : null   }  sx={{ width:{lg:"17rem", sm:"17rem",md:"17rem" ,xs:"17rem" } }} InputLabelProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} label="PAN Number" />
<TextField InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} value={userAddress} onChange={(e) => setUserAddress(e.target.value) }  sx={{ width:{lg:"17rem", sm:"17rem",md:"17rem" ,xs:"17rem" } }} InputLabelProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} inputProps={{ style:{ textTransform:'capitalize' } }} label="Communication Address" />
  </Box>

  <Box sx={{  display:'flex', alignItems:'center', justifyContent:"space-evenly", width:"100%", flexDirection:{lg:"row", md:"row", sm:"column", xs:"column" }, gap:1 }} >
  
  <TextField
  InputProps={{
    style: { fontFamily: 'montserrat', fontWeight: 500 }
  }}
  sx={{
    width: { lg: "11rem", md: "11rem", sm: "11rem", xs: "17rem" }
  }}
  value={userCity}
  onChange={(e) => {
    const value = e.target.value;
    // Regex to allow only alphabets and spaces
    if (/^[a-zA-Z\s]*$/.test(value) && value?.length <= 30 ) {
      setUserCity(value);
    }
  }}
  InputLabelProps={{
    style: { fontFamily: 'montserrat', fontWeight: 500 }
  }}
  inputProps={{
    style: { textTransform: 'capitalize' }
  }}
  label="City"
/>


    <TextField InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} sx={{ width:{lg:"11rem", md:"11rem", sm:"11rem", xs:"17rem" } }} value={userState}
     onChange={(e) => {
      const value = e.target.value;

      if (/^[a-zA-Z\s]*$/.test(value) && value?.length <= 30 ){
        setUserState(e.target.value);
      }
     }}
     InputLabelProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} inputProps={{ style:{ textTransform:'capitalize' } }} label="State" />


    <TextField type='number'  InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500, maxLength:6 } }} sx={{ width:{lg:"11rem", md:"11rem", sm:"11rem", xs:"17rem" }, "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {display: "none"},"& input[type=number]": {  MozAppearance: "textfield",  }, }} value={userPinCode} onChange={handlePinCodeChange} InputLabelProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} inputProps={{ style:{ textTransform:'capitalize' } }} label="PinCode" />
  </Box>

{
isLoading ? <CircularProgress />:
<Button onClick={ () => submitUpdate()} variant='contained' sx={{ fontFamily:'montserrat', fontWeight:500 }} fullWidth >Submit</Button>
}
</Box>
</Box>
}

</Box>
    </DialogContent>

  <SnackBarSuccess open={activateSOpen} status={status} message={jsonSError} snackClose={() => setActiveSOpen(false)} />

  </Dialog>  )
}

export default ProfileSection